<template>
    <div>
        <h3>Datos de la Agrupadora</h3>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6" xl="4">
              <div class="image-container pa-3" style="background-color: white;" @click="changeBanner">
                  <v-img
                    v-if="bannerRoute"
                    :lazy-src="bannerRoute"
                    max-height="90"
                    :src="bannerRoute"
                    contain
                    position="right center"
                  ></v-img>
                  <v-img
                    v-else
                    lazy-src="/img/insert.png"
                    max-height="130"
                    src="/img/insert.png"
                ></v-img>
              </div>
            </v-col>
            <input
            @change="getFileClickCompany($event.target.files)"
            type="file"
            name="userProfileBanner"
            accept=".png,.jpg,.jpeg,.gif,.webp"
            id="userProfileBannerCompany"
            hidden
            />
            <v-col cols="12" lg="6" xl="8">
              <div>
                <h4>Logo</h4>
                <p class="caption font-weight-regular">Este Logo aparecerá en los documentos y reportes del software. Le recomendamos que use una imagen de 300x100 píxeles como mínimo y con fondo blanco, de lo contrario podría verse borrosa, cortada o alterada al momento de imprimir.<br>Para cambiar el logo de un click sobre el recuadro y seleccione una imagen de su computador o celular.</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
            v-model="name"
            :counter="nameMax"
            :rules="nameRules"
            label="Nombre o Razón Social"
            ></v-text-field>

            <v-text-field
            v-model="ID"
            :rules="IDRules"
            label="NIT o Cédula"
            type="number"
            ></v-text-field>

            <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Correo Electrónico"
            ></v-text-field>

            <v-text-field
            v-model="mobile"
            :rules="mobileRules"
            label="Número Celular"
            type="number"
            ></v-text-field>

            <v-text-field
            v-model="address"
            :rules="addressRules"
            :counter="200"
            label="Dirección"
            ></v-text-field>

            <v-select
            v-model="country"
            :items="countries"
            item-value="_id"
            item-text="name"
            label="País"
            disabled
          ></v-select>

          <v-autocomplete
            label="Departamento"
            v-model="state"
            :items="states"
            item-text="name"
            item-value="_id"
            @change="sortCities"
          ></v-autocomplete>

          <v-autocomplete
            label="Municipio"
            v-model="city"
            :items="cities"
            item-text="name"
            item-value="_id"
          ></v-autocomplete>

          <v-text-field
            v-model="website"
            :rules="websiteRules"
            label="Sitio Web"
            ></v-text-field>

            <v-btn
            :disabled="!valid"
            color="primary"
            @click="validate"
            >
            Actualizar
            </v-btn>
        </v-form>
    </div>
</template>

<style lang="css" scoped>
  .image-container {
    width: 280px;
    border: black dashed 2px;
    cursor: pointer;
  }
</style>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import axios from "axios";

import { titleCase } from "@/modules/tools";

const store = new Storage();

  export default {
    data: () => ({
      valid: true,
      name: '',
      bannerRoute: null,
      nameMax: 1,
      nameRules: [
        v => (v && v.length <= 50) || `El nombre debe tener menos de 50 caracteres`,
      ],
      ID: '',
      IDMax: 1,
      IDRules: [
        v => (v && v <= 10000000000) || `El Número de Celular debe ser un número inferior a 10000000000`,
      ],
      email: '',
      emailRules: [
        v => /.+@.+\..+/.test(v) || 'El Correo Electrónico debe ser válido',
      ],
      mobile: '',
      mobileMax: 1,
      mobileRules: [
        v => (v && v <= 10000000000) || `El Número de Celular debe ser un número inferior a 10000000000`,
      ],
      address: '',
      addressMax: 1,
      addressRules: [
        v => (v && v.length <= 200) || `La Dirección debe contener menos de 200 caracteres`,
      ],
      country: '',
      countries: [],
      state: '',
      states: [],
      city: '',
      cities: [],
      website: '',
      websiteRules: [
        v => /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(v) || 'El Sitio Web debe ser válido',
      ],
    }),
    async mounted() {
      const schemaData = await store.getData("schema");
      const companyData = await store.getData("company");
      const countryData = await store.getData("country");
      const satetData = await store.getData("state");
      this.bannerRoute = companyData[0].bannerRoute;
      schemaData.forEach(schema => {
          if (schema.store === "company") {
              this.IDMax = schema.data.ID.max;
              this.nameMax = schema.data.name.max;
              this.addressMax = schema.data.address.max;
              this.mobileMax = schema.data.mobile.max;
          }
      });
      satetData.sort(function(a, b) {
          return a.name.localeCompare(b.name);
      });
      countryData.forEach(country => {
        if (country._id == companyData[0].country) {
          country.name = titleCase(country.name);
          this.countries.push(country);
          this.country = country._id;
          satetData.forEach(state => {
            if(state.countryCode == country.ISOCode) {
              state.name = titleCase(state.name);
              this.states.push(state);
            }
          });
        }
      });
      if (companyData[0].name) {
        this.name = companyData[0].name;
      }
      if (companyData[0].ID) {
        this.ID = companyData[0].ID;
      }
      if (companyData[0].email) {
        this.email = companyData[0].email;
      }
      if (companyData[0].mobile) {
        this.mobile = companyData[0].mobile;
      }
      if (companyData[0].address) {
        this.address = companyData[0].address;
      }
      if (companyData[0].website) {
        this.website = companyData[0].website;
      }
      if (companyData[0].state) {
        this.state = companyData[0].state;
      }
      if (companyData[0].city) {
        this.sortCities();
        this.city = companyData[0].city;
      }
    },
    methods: {
      changeBanner() {
        document.getElementById("userProfileBannerCompany").click();
      },
      async getFileClickCompany(files) {
      if (files.length === 0) {
          return true;
      }

      const companyData = await store.getData("company");
      Swal.fire({
          icon: "warning",
          title: "Actualizando logo",
          text: "Estamos actualizando la información",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          stopKeydownPropagation: true
      });

      if (files[0].type.startsWith("image/")) {
          const avatarFile = new FormData();
          const URL =
          new Storage().baseURL +
          "/misalud/company/upload";
          avatarFile.append("banner", files[0]);
          avatarFile.append("_id", companyData[0]._id);


          /*
          axios
          .post(URL, avatarFile, { withCredentials: true })
          */
          store
          .uploadFile(
            "company",
            companyData[0]._id,
            "/misalud/company/upload",
            avatarFile
          )
          .then(res => {
              location.reload();
          })
          .catch(() => {
              Swal.fire({
              icon: "error",
              title: "No es posible realizar esta acción",
              text:
                  "Para cambiar el logo es necesario la conexión a Internet"
              });
          });
      } else {
          Swal.fire({
          icon: "error",
          title: "Tipo de archivo no permitido",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
          });
      }
      },
      async validate () {
        const companyData = await store.getData("company");

        this.$refs.form.validate();
        Swal.fire({
          icon: "warning",
          title: "Actualizando Información de la Agrupadora",
          html:
          "Por favor espere mientras se actualiza los datos de la Agrupadora</b> " +
          '<progress id="progressBar" value="0" max="100"></progress>',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          stopKeydownPropagation: true
        });
        try {
          const URL = new Storage().baseURL + "/misalud/company/" + companyData[0]._id;
          let data = {
            _id: companyData[0]._id,
            name: this.name,
            ID: this.ID,
            email: this.email,
            mobile: this.mobile,
            address: this.address,
            state: this.state,
            city: this.city,
            website: this.website
          };
          const entries = Object.entries(data);
          entries.forEach(entry => {
            if(data[entry[0]] == '') {
              data[entry[0]] = null;
            }
          });
          data = [data];
          const response = await axios
            .put(URL, data, {
              withCredentials: true
            });
          store.updateData("company", response.data.body);
          Swal.fire({
            icon: "success",
            title: "Agrupadora actualizada"
          }).then(() => {
              this.$router.go();
          });
        } catch (error) {
          Swal.fire({
              icon: "error",
              title: "Error al actualizar la Agrupadora"
          }).then(() => {
              this.$router.go();
          });
        }
      },
      async sortCities() {
            const cityData = await store.getData("city");
            this.cities = [];
            cityData.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
            cityData.forEach(city => {
                if (city.state === this.state) {
                    city.name = titleCase(city.name);
                    this.cities.push(city); 
                }
            });
        }
    },
  }
</script>