<template>
    <div>
        <h3>Datos del Usuario</h3>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
            v-model="name"
            :counter="50"
            :maxlength="50"
            :rules="nameRules"
            label="Nombre"
            required
            ></v-text-field>

            <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Correo Electrónico"
            required
            ></v-text-field>

            <v-text-field
            v-model="mobile"
            :rules="mobileRules"
            label="Número Celular"
            type="number"
            required
            ></v-text-field>

            <v-btn
            :disabled="!valid"
            color="primary"
            @click="validate"
            >
            Actualizar
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import Storage from "@/modules/storage";
import axios from "axios";
import Swal from "sweetalert2";

  export default {
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'El Nombre es Requerido',
        v => (v && v.length <= 50) || 'El Nombre debe tener menos de 50 caracteres',
      ],
      email: '',
      emailRules: [
        v => !!v || 'El Correo Electrónico es Requerido',
        v => /.+@.+\..+/.test(v) || 'El Correo Electrónico debe ser válido',
      ],
      mobile: '',
      mobileRules: [
        v => !!v || 'El Número de Celular es Requerido',
        v => (v && v <= 100000000000000) || 'El Número de Celular debe ser un número inferior a 100000000000000',
      ],
    }),
    mounted() {
      this.name = localStorage.getItem('user.name');
      this.email = localStorage.getItem('user.email');
      this.mobile = localStorage.getItem('user.mobile');
    },
    methods: {
      async validate () {
        this.$refs.form.validate();
        Swal.fire({
          icon: "warning",
          title: "Actualizando información",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          stopKeydownPropagation: true,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 100);
          }
        });
        let emailFlag = false;
        const URL =
          new Storage().baseURL +
          "/auth/user/" +
          localStorage.getItem("user._id");
        const sendData = [
          {
            name: this.name
          }
        ];
        if (this.mobile !== "") {
          sendData[0].mobile = this.mobile;
        } else {
          sendData[0].mobile = null;
        }
        if (
          this.email !== "" &&
          this.email !== localStorage.getItem("user.email")
        ) {
          sendData[0].email = this.email;
          emailFlag = true;
        }
        const response = await axios.put(URL, sendData, {
          withCredentials: true
        });
        localStorage.setItem("user.name", response.data.body[0].name);
        localStorage.setItem("user.mobile", response.data.body[0].mobile);
        if (emailFlag) {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text:
              "Por favor verifique su bandeja de correo electrónico para hacer el cambio de Email"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        }
      }
    },
  }
</script>