<template>
    <div @click="showConfirmation = true">
        <h3>Cambio de Contraseña</h3>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="currentPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                label="Contraseña Actual"
                hint="Mínimo 8 caracteres"
                counter
                @click:append="show1 = !show1"
                required
            ></v-text-field>

            <v-text-field
                @keyup="checkPassword"
                v-model="newPassword"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show2 ? 'text' : 'password'"
                label="Nueva Contraseña"
                hint="Mínimo 8 caracteres"
                counter
                @click:append="show2 = !show2"
                required
            ></v-text-field>

            <v-text-field
                @keyup="checkPassword"
                v-model="confirmPassword"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show3 ? 'text' : 'password'"
                label="Confirmar Contraseña"
                hint="Mínimo 8 caracteres"
                counter
                @click:append="show3 = !show3"
                required
            ></v-text-field>

            <v-list disabled v-if="showConfirmation">
            <v-subheader>{{ passowrdMsg }}</v-subheader>
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              multiple
            >
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

            <v-btn
            :disabled="!valid"
            color="primary"
            @click="validate"
            >
            Actualizar
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import { validatePassword } from "@/modules/tools";

import Swal from "sweetalert2";
import axios from "axios";
import Storage from "@/modules/storage";

  export default {
    data: () => ({
      valid: true,
      show1: false,
      show2: false,
      show3: false,
      passowrdMsg: 'Contraseña No Válida',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      rules: {
        required: value => !!value || 'Requerido.',
        min: v => v.length >= 8 || 'Mínimo 8 caracteres',
      },
      showConfirmation: false,
      selectedItem: [],
      items: [
        { text: 'Las contraseñas coinciden' },
        { text: 'Una letra en mayúscula' },
        { text: 'Una letra en minúscula' },
        { text: 'Un dígito' },
        { text: 'Un caracter especial ñÑ ~!@#$%^&*+-/.,{}[]();:' },
      ],
    }),

    methods: {
      async validate () {
        this.$refs.form.validate();
        if (
          !this.selectedItem.includes(0) ||
          !this.selectedItem.includes(1) ||
          !this.selectedItem.includes(2) ||
          !this.selectedItem.includes(3) ||
          !this.selectedItem.includes(4)
        ) {
          Swal.fire({
            icon: "error",
            title: "La nueva contraseña no cumple los paramétros mínimos",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          return 0;
        } else if (this.newPassword === this.currentPassword) {
          Swal.fire({
            icon: "error",
            title: "La nueva contraseña no debe ser la contraseña anterior",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          return 0;
        }
        Swal.fire({
          icon: "warning",
          title: "Actualizando información",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          stopKeydownPropagation: true,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            setInterval(() => {
              const content = Swal.getContent();
              if (content) {
                const b = content.querySelector("b");
                if (b) {
                  b.textContent = Swal.getTimerLeft();
                }
              }
            }, 100);
          }
        });
        const URL = new Storage().baseURL + "/auth/user/changePassword";
        const sendData = [
          {
            password: this.currentPassword,
            newPassword: this.confirmPassword
          }
        ];
        try {
         await axios
          .post(URL, sendData, {
            withCredentials: true
          }) 
        } catch (error) {
          Swal.fire({
            icon: "error",
            title:
              "La contraseña actual no coincide con la registrada anteiormente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          return 0;
        }
        Swal.fire({
          icon: "success",
          title: "Contraseña Actualizada",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
      },
      deleteItem(item) {
        const index = this.selectedItem.indexOf(item);
        if (index > -1) {
          this.selectedItem.splice(index, 1);
        }
      },
      checkPassword() {
        if (this.newPassword === "" && this.confirmPassword === "") {
          this.deleteItem(0);
        } else if (this.newPassword === this.confirmPassword) {
          this.selectedItem.push(0);
        } else {
          this.deleteItem(0);
        }

        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const specialLetters = "ñÑ~!@#$%^&*+-/.,{}[]();: ";
        const digits = "0123456789";

        this.deleteItem(1);
        this.deleteItem(2);
        this.deleteItem(3);
        this.deleteItem(4);
        this.passowrdMsg = 'Contraseña No Válida';

        for (let i = 0; i < this.newPassword.length; i++) {
          if (lowercase.includes(this.newPassword.charAt(i)) && !this.selectedItem.includes(2)) {
            this.selectedItem.push(2);
          } else if (uppercase.includes(this.newPassword.charAt(i)) && !this.selectedItem.includes(1)) {
            this.selectedItem.push(1);
          } else if (specialLetters.includes(this.newPassword.charAt(i)) && !this.selectedItem.includes(4)) {
            this.selectedItem.push(4);
          } else if (digits.includes(this.newPassword.charAt(i)) && !this.selectedItem.includes(3)) {
            this.selectedItem.push(3);
          }
        }
        if (
          this.selectedItem.includes(0) &&
          this.selectedItem.includes(1) &&
          this.selectedItem.includes(2) &&
          this.selectedItem.includes(3) &&
          this.selectedItem.includes(4)
        ) {
          this.passowrdMsg = 'Contraseña Válida';
        }
      }
    },
  }
</script>