<template>
    <div>
        <User></User>
        <v-container class="main-content">
            <v-row>
                <v-col cols="12" md="8">
                    <v-card
                    class="py-4 px-7"
                    outlined
                    tile
                    >
                    <UserData></UserData>
                    </v-card>
                    <v-card
                    class="py-4 px-7 mt-5"
                    outlined
                    tile
                    >
                    <CompanyData></CompanyData>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card
                    class="py-4 px-7"
                    outlined
                    tile
                    >
                    <Password></Password>
                    </v-card>
                    <v-card
                    class="py-4 px-7 mt-5"
                    outlined
                    tile
                    >
                    <Danger></Danger>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import User from '@/components/settings/User';
import UserData from '@/components/settings/UserData';
import CompanyData from '@/components/settings/CompanyData';
import Password from '@/components/settings/Password';
import Danger from '@/components/settings/Danger';
export default {
    name: 'UserSettings',
    components: {
        User,
        UserData,
        CompanyData,
        Password,
        Danger
    }
}
</script>