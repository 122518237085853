<template>
    <div>
        <div>
            <v-img
            v-if="bannerURL"
            :lazy-src="bannerURL"
            max-height="250"
            :src="bannerURL"
            ></v-img>
            <v-img
            v-else
            lazy-src="https://storage.assoft.co/img/default/banner.jpg"
            max-height="250"
            src="https://storage.assoft.co/img/default/banner.jpg"
            ></v-img>
            <div id="bannerButton" class="d-flex justify-end">
                <v-btn
                elevation="2"
                color="primary"
                class="mx-5"
                @click="changeBanner"
                >Cambiar Portada</v-btn>
            </div>
            <input
            @change="getFileClick($event.target.files)"
            type="file"
            name="userProfileBanner"
            accept=".png,.jpg,.jpeg,.gif,.webp"
            id="userProfileBanner"
            hidden
            />
        </div>
        <div class="avatar-main mx-6">
            <div>
                <v-avatar
                v-if="avatarURL"
                style="cursor: pointer"
                @click="changeAvatar"
                size="100"
                ><img :src="avatarURL"></v-avatar>
                <v-avatar
                v-else
                style="cursor: pointer"
                @click="changeAvatar"
                color="brown"
                size="100"
                ><span class="white--text headline">{{ userName.substring(0, 1).toUpperCase() }}</span></v-avatar>
                <h3 class="mt-2">{{ userName }}</h3>
                <p>Administrador</p>
            </div>
            <input
            @change="getFileClickAvatar($event.target.files)"
            type="file"
            name="userProfileAvatar"
            accept=".png,.jpg,.jpeg,.gif,.webp"
            id="userProfileAvatar"
            hidden
            />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

export default {
    name: 'SettingsUser',
    data() {
        return {
            bannerURL: false,
            avatarURL: false
        }
    },
    computed: {
        userName: () => {
            return localStorage.getItem('user.name');
        }
    },
    mounted() {
        if (localStorage.getItem("user.bannerURL") != "null") {
        this.bannerURL =
            localStorage.getItem("user.bannerURL") +
            "?seed=" +
            Math.floor(Math.random() * 10000000);
        }
        if (localStorage.getItem("user.avatarURL") != "null") {
            this.avatarURL =
            localStorage.getItem("user.avatarURL") +
            "?seed=" +
            Math.floor(Math.random() * 10000000);
        }
    },
    methods: {
        changeBanner() {
            document.getElementById("userProfileBanner").click();
        },
        changeAvatar() {
            document.getElementById("userProfileAvatar").click();
        },
        getFileClickAvatar(files) {
        if (files.length === 0) {
            return true;
        }

        Swal.fire({
            icon: "warning",
            title: "Actualizando imagen de perfil",
            text: "Estamos actualizando la imagen de perfil",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            stopKeydownPropagation: true
        });

        if (files[0].type.startsWith("image/")) {
            const avatarFile = new FormData();
            const URL =
            new Storage().baseURL +
            "/auth/user/" +
            localStorage.getItem("user._id") +
            "/upload";
            avatarFile.append("avatar", files[0]);

            axios
            .post(URL, avatarFile, { withCredentials: true })
            .then(res => {
                localStorage.setItem("user.avatarURL", res.data.body[0].avatarURL);
                this.avatarURL = res.data.body[0].avatarURL;
                location.reload();
            })
            .catch(() => {
                Swal.fire({
                icon: "error",
                title: "No es posible realizar esta acción",
                text:
                    "Para cambiar la foto de perfil es necesario conexión a Internet"
                });
            });
        } else {
            Swal.fire({
            icon: "error",
            title: "Tipo de archivo no permitido",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
            });
        }
        },
        getFileClick(files) {
        if (files.length === 0) {
            return true;
        }

        Swal.fire({
            icon: "warning",
            title: "Actualizando banner",
            text: "Estamos actualizando la información",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            stopKeydownPropagation: true
        });

        if (files[0].type.startsWith("image/")) {
            const avatarFile = new FormData();
            const URL =
            new Storage().baseURL +
            "/auth/user/" +
            localStorage.getItem("user._id") +
            "/upload";
            avatarFile.append("banner", files[0]);

            axios
            .post(URL, avatarFile, { withCredentials: true })
            .then(res => {
                localStorage.setItem("user.bannerURL", res.data.body[0].bannerURL);
                location.reload();
            })
            .catch(() => {
                Swal.fire({
                icon: "error",
                title: "No es posible realizar esta acción",
                text:
                    "Para cambiar la foto de perfil es necesario la conexión a Internet"
                });
            });
        } else {
            Swal.fire({
            icon: "error",
            title: "Tipo de archivo no permitido",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
            });
        }
        }
    },
}
</script>

<style lang="css" scoped>
    .avatar-main {
        margin-top: -40px;
    }
    #bannerButton {
        margin-top: -3em;
    }
    @media only screen and (max-width: 600px) {
        .avatar-main {
            display: flex;
            justify-content: center;
            text-align: center;
        }
        #bannerButton {
            position: absolute;
            top: 60px;
            justify-content: center !important;
            width: 100vw;
        }
    }
</style>